import React, { useEffect, useState } from 'react';
import axios from 'axios';
import deleteImage from '../../img/Icons/clear.png';
import download from '../../img/Icons/download.png';
import jsPDF from 'jspdf';
import './ConversationHistory.css';

const ConversationHistory = (props) => {
    const [conversationHistory, setConversationHistory] = useState([]);
    const [chatSession, setChatSession] = useState('');

    useEffect(() => {
        // Retrieve the chat session from localStorage
        const storedChatSession = localStorage.getItem('chatSession');

        // If a chat session is not present, generate a new one
        if (!storedChatSession) {
            const newChatSession = `chat${String(localStorage.length + 1).padStart(3, '0')}`;
            setChatSession(newChatSession);
            localStorage.setItem('chatSession', newChatSession);
        } else {
            setChatSession(storedChatSession);
        }

        // Fetch conversation history for the current chat session
        axios.get(`https://rosaparks.life:5010/api/conversation-history?chatSession=${chatSession}`)
            .then((response) => {
                setConversationHistory(response.data);
            })
            .catch((error) => {
                console.error('Error fetching conversation history:', error);
            });
    }, [chatSession]);


    // Function to format the timestamp
    const formatTimestamp = (timestamp) => {
        const date = new Date(timestamp);
        return date.toLocaleString();
    };

    //Delete button
    const handleDeleteRecords = () => {
        axios.delete('https://rosaparks.life:5010/api/delete-conversation-records')
            .then(() => {
                setConversationHistory([]);
            })
            .catch((error) => {
                console.error('Error deleting conversation records:', error);
            });
    };

    // Function to download PDF
    const handleDownloadPDF = () => {
        const doc = new jsPDF();
        doc.setFontSize(12);
        
        conversationHistory.forEach((item, index) => {
          if (index !== 0) {
            doc.addPage(); // Add a new page for each record except the first one
          }
      
          // Split input text into lines
          const textLines = doc.splitTextToSize(item.input, 180);
      
          doc.text(`Record ${index + 1}`, 10, 10);
          doc.text(`Timestamp: ${formatTimestamp(item.timestamp)}`, 10, 20);
      
          // Calculate available space for response
          const availableSpace = doc.internal.pageSize.height - 30;
      
          // Split response text into lines that fit the available space
          const responseLines = doc.splitTextToSize(item.response, 180, { maxLines: availableSpace / 10 });
      
          // Add input lines
          for (let i = 0; i < textLines.length; i++) {
            doc.text(`User: ${textLines[i]}`, 10, 30 + (i * 10));
          }
      
          // Add response lines, ignoring emojis
          for (let i = 0; i < responseLines.length; i++) {
            if (i === 0) {
              doc.text(`Knightingale: ${responseLines[i]}`, 10, 30 + (textLines.length * 10) + (i * 10));
            } else {
              doc.text(responseLines[i], 10, 30 + (textLines.length * 10) + (i * 10));
            }
          }
      
          // Add horizontal line for the last line of each record
          if (index < conversationHistory.length - 1) {
            doc.setLineWidth(0.5);
            doc.line(10, doc.internal.pageSize.height - 10, 200, doc.internal.pageSize.height - 10);
          }
        });
      
        doc.save('conversation_history.pdf');
      };
      
  


    
    return (
        <div className="conversation-history">
            <h2>Conversation History</h2>
            <button onClick={handleDeleteRecords} className="delete-button">
                <img src={deleteImage} alt="Delete" className="delete-icon" />
            </button>
            <button onClick={handleDownloadPDF} className="download-button">
                <img src={download} alt="Download" className="download-icon" /> 
            </button>
            <div className="conversation-list">
                <ul>
                    {conversationHistory.map((item, index) => (
                        <li key={index} className="conversation-record">
                            <p>Timestamp: {formatTimestamp(item.timestamp)}</p>
                            <p>User: {item.input}</p>
                            <p>Knightingale: {item.response}</p>
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default ConversationHistory;
