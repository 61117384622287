import React, { useEffect, useRef, useState } from "react";
import Form from "../Form/Form";
import {  useParams } from "react-router-dom";
import userlogo from "../../img/Icons/login.png";
import mainlogo from "../../img/logo/celebrating_rosa_parks_logo.png";
import LoginPage from "../Login/Login";
import "./MainLayout.css";
import "../Start/Start.css";
import Navbar from "../Navbar/Navbar";
import home_icon from "../../img/versionthree/home_icon.png";
import share_icon from "../../img/versionthree/share_icon.png";
import chat_icon from "../../img/versionthree/chat_icon.png";
import burger_menu from "../../img/versionthree/burger_menu.svg";
import back_icon from "../../img/versionthree/back_icon.svg";
import rosa_park_mobile_lip_idle from "../../videos/chat/rosa_park_mobile_lip_idle.mp4";
import rosa_park_mobile_lip_lip_synced from "../../videos/chat/rosa_park_mobile_lip_lip_synced.mp4";
import rosa_park_dekstop_lip_idle from "../../videos/chat/rosa_park_dekstop_lip_idle.mp4";
import rosa_park_dekstop_lip_synced from "../../videos/chat/rosa_park_dekstop_lip_synced.mp4";
import rosa_park_dekstop_thumbnail from "../../img/rosa_park_dekstop_thumbnail.jpg";
import rosa_park_mobile_thumbnail from "../../img/chat/rosa_park_mobile_thumbnail.jpg";
import { useShareWindow } from "../../context/ShareWindowContext.js";
import ShareWindow from "../Sharewindow/ShareWindow.js";
import axios from "axios";
import Afternoon from "../../videos/chat/Afternoon_Icon.mp4";
import Morning from "../../videos/chat/Morning_Icon.mp4";
import Night from "../../videos/chat/Night_Icon.mp4";
import Evening from "../../videos/chat/Evening_Icon.mp4";


const SET_NICKNAME = "https://rosaparks.life:5010/get-user-nickname";

function Test(props) {
  const buttonRef = useRef(null);
  const [backgroundColor, setBackgroundColor] = useState("transparent");
  const [backgroundMp, setBackgroundMp] = useState(rosa_park_dekstop_lip_idle);
  const [centerHome, setCenterHome] = useState(true);
  const [mobileNav, setMobileNav] = useState(false);
  const [nickname, setNickname] = useState("");
  const { session: urlSession } = useParams();
  const {
    showShareWindow,
    setShowShareWindow,
    chatSession,
    setChatSession,
    setBackgroundText
    ,  setBackgroundImg, minMaxHeight, backgroundreturnColor, setBackgroundreturnColor, deskNav,  isStyled, setIsStyled, setChatStart, audioVideoChange
  } = useShareWindow();



  useEffect(() => {
    // Retrieve value from localStorage when component mounts
    const chatType = localStorage.getItem('chatType');


    if (chatType) {
      // alert(`Retrieved value from localStorage: ${chatType}`);
      setIsStyled(!isStyled);
      setChatStart(true);

      localStorage.removeItem('chatType');
    }

    return (chatType)


  }, []);

  const uniqueLink = `https://rosaparks.life/share/${chatSession}`;
  // const uniqueLink = `https://.knightingale.ai/knask-video/share/${chatSession}`;

  const [conversationHistory, setConversationHistory] = useState([]);




  // useEffect(() => {
  //   // Fetch user nickname from the backend
  //   fetch(SET_NICKNAME)
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setNickname(data.nickname);
  //     })
  //     .catch((error) => console.error("Error fetching user nickname:", error));
  // }, []);

  useEffect(() => {
    buttonRef.current.click();
  }, []);

  const videoRef = useRef(null);

  const handlePlayClick = () => {
    const video = videoRef.current;
    if (video) {
      video.play().catch((error) => {
        console.error("Autoplay error:", error);
      });
    }
  };

  const [isPopupOpen, setIsPopupOpen] = useState(false);

  const openPopup = () => {
    setIsPopupOpen(true);
  };

  const closePopup = () => {
    setIsPopupOpen(false);
  };

  const [greeting, setGreeting] = useState("Good Morning");
  const [image, setImage] = useState(Morning);

  useEffect(() => {
    const updateGreetingAndImage = () => {
      const currentHour = new Date().getHours();

      let newGreeting, newImage;

      if (currentHour >= 5 && currentHour < 12) {
        newGreeting = "Good Morning";
        newImage = Morning;
      } else if (currentHour >= 12 && currentHour < 17) {
        newGreeting = "Good Afternoon";
        newImage = Afternoon;
      } else if (currentHour >= 17 && currentHour < 21) {
        newGreeting = "Good Evening";
        newImage = Evening;
      } else {
        newGreeting = "Good Night";
        newImage = Night;
      }

      setGreeting(newGreeting);
      setImage(newImage);
    };

    updateGreetingAndImage(); // Initial update

    const intervalId = setInterval(updateGreetingAndImage, 60000);

    return () => clearInterval(intervalId); // Clear interval on unmount
  }, []);

  const divStyle = {
    backgroundColor: backgroundColor,
  };

  const backgroundOpacity = .5;

  const returnStyle = {
    backgroundColor: `rgba(${parseInt(
      backgroundreturnColor.slice(1, 3),
      16
    )}, ${parseInt(backgroundreturnColor.slice(3, 5), 16)}, ${parseInt(
      backgroundreturnColor.slice(5, 7),
      16
    )}, ${backgroundOpacity})`,
    borderColor: backgroundreturnColor,
  };

  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    setLoaded(true);
  }, []);


  useEffect(() => {
    const updateScreenHeight = () => {
      const newHeight = Math.max(648, Math.min(1500, window.innerHeight));
    };

    // Update screen height when the window is resized
    window.addEventListener("resize", updateScreenHeight);

    // Initial update
    updateScreenHeight();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", updateScreenHeight);
    };
  }, []);

  let backClicked = false;

  if (!centerHome) {
    window.addEventListener("popstate", function (event) {
      if (!backClicked) {
        backClicked = true;
      } else {
        window.addEventListener("hashchange", function () {
          window.location.reload();
        });
      }
    });
  }

  useEffect(() => {
    if (centerHome === false) {
      const handleClick = () => {
      };

      const autoclickElement = document.getElementById("autoclick");

      if (autoclickElement) {
        autoclickElement.addEventListener("click", handleClick);
        autoclickElement.click();
        return () => {
          autoclickElement.removeEventListener("click", handleClick);
        };
      }
    }
  }, [centerHome]);


  useEffect(() => {
    if (urlSession) {
      // If a session ID is present in the URL, use it
      setChatSession(urlSession);

      // Fetch conversation history for the current chat session
      axios
        .get(`https://rosaparks.life:5010/api/conversation-history/${urlSession}`)
        .then((response) => {
          // Update conversation history state
          setConversationHistory(response.data);
        })
        .catch((error) => {
          console.error("Error fetching conversation history:", error);
        });
    } else {
      // If no session ID in the URL, fetch a new session ID when the component mounts
      axios
        .get("https://rosaparks.life:5010/api/generate-session")
        .then((response) => {
          const newChatSession = response.data.chatSession;
          setChatSession(newChatSession);
          // Store the new session ID in localStorage
          localStorage.setItem("chatSession", newChatSession);
        })
        .catch((error) => {
          console.error("Error generating session:", error);
        });
    }
  }, [urlSession]);


  const [showTooltip, setShowTooltip] = useState(false);
  const [hoverTimeout, setHoverTimeout] = useState(null);
  const [zstyle, setZstyle] = useState(false);

  const handleMouseEnterShare = () => {
    setZstyle(true)
    const timeout = setTimeout(() => {
      setShowTooltip(true);
    }, 2000); // 2 seconds
    setHoverTimeout(timeout);
  };

  const handleMouseLeaveShare = () => {
    clearTimeout(hoverTimeout);
    setShowTooltip(false);
    setZstyle(false)
  };


  const newChatClick = () => {
    // Store 'newchat' in localStorage
    localStorage.setItem('chatType', 'newchat');
    // alert("Value 'newchat' has been stored in localStorage.");
  };



  const [objectFitStyle, setObjectFitStyle] = useState('');

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;
      const screenRatio = screenWidth / screenHeight;

      // Check the screen ratio and set the object-fit style accordingly
      if (screenRatio < 1.8) {
        setObjectFitStyle('cover');
      } else {
        setObjectFitStyle('cover');
      }
    };

    // Call handleResize on initial mount and window resize
    handleResize();
    window.addEventListener('resize', handleResize);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);





  return (
    <div
      className={`main-three-colum-layout-wrapper  ${!isStyled ? 'start-bg-color' : ''} `}
      style={divStyle}
    >

      <div className="main-three-colum-layout-container">

        <div className="desk_home_bg_video_wrapper">
          <div className="desk_home_bg_video_container">
            <div className="new-background-video-container-chat videoclass">
              <div
                style={{
                  width: '100%',
                  height: 'auto',
                  opacity: `${audioVideoChange ? '0' : ''}`,
                  position: 'absolute',
                  transition: 'opacity 1ms',
                }}

                className="video_wrapper_dp"
              >

                <video
                  className={`new-background-bg-video-container-chat-video desktop_background_video `}
                  style={{
                    objectFit: objectFitStyle,
                    height: '100%',
                    width: '100%',
                  }}
                  playsInline
                  autoPlay
                  loop
                  muted
                  poster={rosa_park_dekstop_thumbnail}
                  src={rosa_park_dekstop_lip_idle}
                />

                <video
                  className={`new-background-bg-video-container-chat-video mobile_background_video `}
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }}
                  playsInline
                  autoPlay
                  loop
                  muted
                  poster={rosa_park_mobile_thumbnail}
                  src={rosa_park_mobile_lip_idle}
                />
              </div>
              <div
                style={{
                  opacity: `${audioVideoChange ? '1' : '0'}`,
                  transition: 'opacity 1ms',
                  position: 'absolute',
                  height: '100%',
                  width: '100%',

                }}

                className="video_wrapper_dp"

              >

                <video
                  className="new-background-bg-video-container-chat-video desktop_background_video"
                  style={{
                    objectFit: objectFitStyle,
                    height: '100%',
                    width: '100%',
                  }
                  }
                  playsInline
                  autoPlay
                  loop
                  muted
                  poster={rosa_park_dekstop_thumbnail}
                  src={rosa_park_dekstop_lip_synced}
                />

                <video
                  className="new-background-bg-video-container-chat-video mobile_background_video"
                  style={{
                    objectFit: 'cover',
                    height: '100%',
                    width: '100%',
                  }
                  }
                  playsInline
                  autoPlay
                  loop
                  muted
                  poster={rosa_park_mobile_thumbnail}
                  src={rosa_park_mobile_lip_lip_synced}
                />
              </div>


            </div>
          </div>
        </div>
<LoginPage isOpen={isPopupOpen} onClose={closePopup}></LoginPage>
        <div
          className={`main-three-colum-layout    ${!isStyled ? 'start-main-three-colum-layout' : ''}`}
          style={divStyle}
        >
          <video
            ref={videoRef}
            className="new-mobile-background-video"
            playsInline
            muted
            loop
          />

          <button
            ref={buttonRef}
            onClick={() => handlePlayClick()}
            style={{ display: "none" }}
          ></button>
          <div className={`left-column   ${!isStyled ? 'start-left-column' : ''}`}>

            
            <div className={`left-column-logo  ${!isStyled ? '' : 'left-column-logo-hide'}`}>
              <a href="https://rosaparks.life/">
                <img
                  className="logo-kps menu-logo-kps "
                  src={mainlogo}
                  alt="Image 1"
                />

              </a>

              <div className="right_side_nav">
                <div className="right_side_nav_inner" onClick={openPopup}>
                <img className="user-kps " src={userlogo} alt="Image 1"  style={{width:'40px'}}/>
                </div>
              </div>
            </div>

            <div className={`left-column-vertical-buttons ${deskNav ? '' : 'deskNav'}`}>
              <div className="vertical-button"
              >
                <a href="https://rosaparks.life/">
                  <img
                    className="vertical-button-img"
                    src={home_icon}
                    alt="Image 1"
                  />
                  <div className="side_nav_text">Home
                  </div>
                </a>
              </div>
              <div className="vertical-button">

                <div className="sharebtn-wrapper start-sharebtn-wrapper">
                  <div className="sharebtn-inner">
                    <div className="sharebtn"
                      onMouseEnter={handleMouseEnterShare}
                      onMouseLeave={handleMouseLeaveShare}
                    >

                      <button
                        className="sharebtn-img tooltip"
                        onClick={() => setShowShareWindow(true)}
                      // disabled={isButtonDisabled}

                      >
                        <img
                          className="vertical-button-img share"
                          src={share_icon}
                          alt="Image 1"
                        />
                        <div className="side_nav_text">Share</div>
                      </button>
                      {showShareWindow && (
                        <ShareWindow
                          generatedLink={uniqueLink}
                          onClose={() => setShowShareWindow(false)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="vertical-button" onClick={newChatClick}>
                <a href="https://rosaparks.life/"  >
                  <img
                    className="vertical-button-img"
                    src={chat_icon}
                    alt="Image 1"
                  />
                  <div className="side_nav_text">New Chat</div>
                </a>
              </div>
            </div>
            <div className="video-wrapper">
              <div className="new-background-video-container-chat videoclass">
             
              </div>
            </div>
          </div>
          {<a href="#" id="autoclick"></a>}

          <div
            className={`center-column  ${!isStyled ? 'start-central-column' : ''}`}
          >

            <div className="mobile_1v3_nav">
              <div className="mobile_1v3_nav_wrapper">
                <div className="mobile_1v3_nav_container">
                  <div className="mobile_1v3_nav_toggle">
                    <div className="toggle_menu_wrapper" onClick={() => { setMobileNav(!mobileNav) }}>
                      <img className="toggle_menu" src={burger_menu} alt="Image 1" />
                    </div>
                  </div>

                  <div className="mobile_1v3_nav_logo">
                    <a href="https://rosaparks.life/">
                      <img
                        className="logo-kps menu-logo-kps "
                        src={mainlogo}
                        alt="Image 1"
                      />
                    </a>
                  </div>
                  <div className="mobile_1v3_nav_profile " onClick={openPopup}> 
                    {/* <img className="user-kps " src={user} alt="Image 1" /> */}
                                  </div>
                </div>
              </div>
            </div>
            <div className="mobile_1v3_side_nav_open start_mobile_1v3_side_nav ">
              <div className={`mobile_1v3_side_nav ${mobileNav ? "mobile_1v3_side_nav_open" : ""
                }`}>
                <div className={`mobile_1v3_side_nav_wrapper   ${mobileNav ? 'mob_bg_overlay' : ''}`}>
                  <div className="mobile_1v3_side_nav_container">
                    <div className="side_nav_menu_container">
                      <div className="side_nav_menu_item_wrapper">
                        <div className="side_nav_menu_item_container">
                          <div className=" side_nav_menu_item_back">
                            <a href="#" onClick={() => { setMobileNav(!mobileNav) }} className="">
                              <img
                                className="vertical-button-img"
                                src={back_icon}
                                alt="Image 1"
                              />
                            </a>
                          </div>
                          <div className="side_nav_menu_item_wrapper">

                            <div className="side_nav_menu_item">
                              <a href="https://rosaparks.life/">
                                <img
                                  className="vertical-button-img"
                                  src={home_icon}
                                  alt="Image 1"
                                />

                                <div className="side_nav_menu_item_text">
                                  Home
                                </div> </a>
                            </div>
                            <div className="side_nav_menu_item" onClick={() => setShowShareWindow(true)}>
                              <div className="sharebtn-wrapper start-sharebtn-wrapper">
                                <div className="sharebtn-inner">
                                  <div className="sharebtn"
                                    onMouseEnter={handleMouseEnterShare}
                                    onMouseLeave={handleMouseLeaveShare}
                                  >

                                    <button
                                      className="sharebtn-img tooltip"
                                      onClick={() => setShowShareWindow(true)}
                                    // disabled={isButtonDisabled}

                                    >
                                      <img
                                        className="vertical-button-img share"
                                        src={share_icon}
                                        alt="Image 1"
                                      />
                                      {showTooltip && <div class="tooltip">
                                        <span class="tooltiptext">Share</span>
                                      </div>}
                                    </button>
                                  </div>
                                </div>
                              </div>
                              <div className="side_nav_menu_item_text">
                                Share
                              </div>
                            </div>

                            <div className="side_nav_menu_item" onClick={newChatClick}>
                              <a href="https://rosaparks.life/"  >
                                <img
                                  className="vertical-button-img"
                                  src={chat_icon}
                                  alt="Image 1"
                                />

                                <div className="side_nav_menu_item_text">
                                  New Chat
                                </div></a>
                            </div>
                          </div>
                        </div>
                        <div className="side_nav_menu_item_bottom">
                          <div className="side_nav_menu_item_bottom_text">
                            © 2024 Knightingale, Inc.
                          </div>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                </div>
              </div>
            </div>


        

            <div className={`central-greeting-wrapper-mobile ${!isStyled ? 'start-central-greeting-wrapper' : ''}`}>
           
            </div>
            <div className={` start-central-video-display  ${!isStyled ? 'start-cental-video-wrapper' : ''}`}>
              <div className="video-wrapper videoclass" id="yourVideoDivId">
                <div className="new-background-video-container videoclass">
                  <video
                    className="start-video"
                    id="start_video"
                    playsInline
                    autoPlay
                    loop
                    muted
                  />
                
                </div>
              </div>
            </div>


            <div style={{}} className="greeting_form_wrapper">
              <div className="central-greeting-wrapper-desktop">
                <div
                  className={`central-greeting-wrapper  app  ${!isStyled ? 'start-central-greeting-wrapper' : ''}  ${loaded ? "loaded" : ""} `} style={{ top: (minMaxHeight * -1) < 2 ? `${-70 + (minMaxHeight * -1)}px` : `${-80}px` }}

                >
                </div>
              </div>
              <Form
                setBackgroundColor={setBackgroundColor}
                setBackgroundImg={setBackgroundImg}
                setBackgroundText={setBackgroundText}
                setBackgroundMp={setBackgroundMp}
                setBackgroundreturnColor={setBackgroundreturnColor}
                backgroundMp={backgroundMp}
                setCenterHome={setCenterHome}
              />
            </div>
          </div>

          <div className="right-column ">
            <Navbar setCenterHome={setCenterHome} />
          </div>
        </div>
        {showShareWindow && (
          <ShareWindow
            generatedLink={uniqueLink}
            onClose={() => setShowShareWindow(false)}
          />
        )}
      </div>
    </div>
  );
}

export default Test;
